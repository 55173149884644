export default {
  place: {
    en: 'Place',
  },
  user: {
    en: 'User',
  },
  total: {
    en: 'Total collected Credits',
  },
  you: {
    en: 'you',
  },
}
