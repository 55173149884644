export default {
  title: {
    en: 'These are our <strong>Piggy Banks</strong>',
  },
  description: {
    en: `
      <b class="block mb:mb-1.5">Join the game and watch your&nbsp;funds&nbsp;level&nbsp;up!</b>
      Deposit your funds and see how easy it is to earn drops! With Piggy Banks, 
      your funds earn you a substantial amount of Credits daily. Use your Credits 
      to open lootboxes and enjoy up to 1000% APY daily in drops!
    `,
  },
}
