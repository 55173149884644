'use client'

import { motion, type Variants } from 'framer-motion'
import Image, { type StaticImageData } from 'next/image'
import { Button, buttonMessages } from 'components/inputs'
import moneySuitcase from 'public/images/pigs/money-suitcase.png'
import coinsPig from 'public/images/pigs/coins.png'
import calculationsPig from 'public/images/pigs/calculations.png'
import messages from './messages'
import { Message } from '@locmod/intl'
import clsx from 'clsx'
import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { openModal } from '@locmod/modal'
import { constants } from 'helpers'

type FeaturesSectionProps = {
  className?: string
}

const CardDots = () => {
  return (
    <div className="mb:hidden">
      <span className="absolute left-7 top-7 m-0.5 h-2.5 w-2.5 bg-black" />
      <span className="absolute right-7 top-7 m-0.5 h-2.5 w-2.5 bg-black" />
      <span className="absolute bottom-7 right-7 m-0.5 h-2.5 w-2.5 bg-black" />
      <span className="absolute bottom-7 left-7 m-0.5 h-2.5 w-2.5 bg-black" />
    </div>
  )
}

const baseCardClassName = 'relative rounded-5 bg-white p-1 shadow-y-2-6 lg:p-2.5 min-w-60'

type Card = {
  title: Intl.Message
  text: Intl.Message
  image: StaticImageData
  isWide?: boolean
  className: string
  variants: Variants
}

const cards: Card[] = [
  {
    title: messages.cards[0].title,
    text: messages.cards[0].text,
    image: calculationsPig,
    isWide: false,
    className: clsx(baseCardClassName, '-rotate-2 lg:-rotate-6'),
    variants: {
      hidden: { opacity: 0, scaleX: 0, x: -100, rotate: '45deg' },
      visible: { opacity: 1, scaleX: 1, x: 0, rotate: 'var(--tw-rotate)' },
    },
  },
  {
    title: messages.cards[1].title,
    text: messages.cards[1].text,
    image: moneySuitcase,
    isWide: true,
    className: clsx(baseCardClassName, 'z-10 mb:-ml-3'),
    variants: {
      hidden: { opacity: 0, scaleX: 0 },
      visible: { opacity: 1, scaleX: 1 },
    },
  },
  {
    title: messages.cards[2].title,
    text: messages.cards[2].text,
    image: coinsPig,
    isWide: false,
    className: clsx(baseCardClassName, 'rotate-[4deg] mb:-ml-3 lg:rotate-[8deg]'),
    variants: {
      hidden: { opacity: 0, scaleX: 0, x: -100, rotate: '-45deg' },
      visible: { opacity: 1, scaleX: 1, x: 0, rotate: 'var(--tw-rotate)' },
    },
  },
]

const FeaturesSection: React.FC<FeaturesSectionProps> = ({ className }) => {
  const handleClick = () => openModal('DepositModal', { placement: 'features section' })

  return (
    <motion.section
      variants={constants.baseAnimationVariants.container}
      className={clsx(className, 'lg:mx-auto lg:max-w-[1328px]')}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.6 }}
    >
      <Message
        value={messages.title}
        tag="h2"
        className="text-center font-source-serif text-px-32 leading-120p text-grey-800 mb:px-4 lg:text-px-40 lg:leading-14"
      />
      <motion.ul
        variants={constants.baseAnimationVariants.itemY}
        className={clsx(
          'no-scrollbar group mt-3 flex max-w-full items-center overflow-x-auto overflow-y-hidden py-3 mb:px-4',
          'grid-cols-[340px_620px_340px] lg:mb-2 lg:grid lg:overflow-visible',
        )}
      >
        {cards.map(({ title, text, image, isWide, className, variants }, index) => (
          <motion.li key={index} variants={variants} className={className}>
            <CardDots />
            <div
              className={clsx(
                'flex flex-col items-center rounded-4 border border-black p-4 pt-2 lg:px-5 lg:py-11',
                isWide && 'lg:flex-row lg:items-start lg:gap-6',
              )}
            >
              <Image
                src={image}
                alt=""
                className={clsx('h-auto w-40', isWide ? 'lg:w-60' : 'lg:w-50')}
                draggable="false"
              />
              <div className={clsx('mt-2 text-center lg:mt-4', isWide && 'lg:text-left')}>
                <Message value={title} className="font-source-serif text-px-24 leading-130p" tag="h3" />
                <Message
                  value={text}
                  className="mt-1 text-px-13 leading-140p text-black/80 lg:mt-4 lg:text-px-16 lg:font-medium lg:leading-150p lg:text-black"
                  tag="p"
                  html
                />
                {isWide && (
                  <div className="mt-4 flex gap-4 mb:hidden">
                    <ConnectButtonWrapper>
                      <Button
                        title={buttonMessages.makeDeposit}
                        styling="second"
                        size="s"
                        leftIcon="ui/pig"
                        onClick={handleClick}
                      />
                    </ConnectButtonWrapper>
                    <Button title={messages.showBox} to="/#cases" styling="third" size="s" />
                  </div>
                )}
              </div>
            </div>
          </motion.li>
        ))}
      </motion.ul>
      <div className="mt-6 flex items-center justify-center gap-4 lg:hidden">
        <ConnectButtonWrapper>
          <Button
            title={buttonMessages.makeDeposit}
            styling="second"
            size="s"
            leftIcon="ui/pig"
            onClick={handleClick}
          />
        </ConnectButtonWrapper>
        <Button title={messages.showBox} to="/#cases" styling="third" size="s" />
      </div>
    </motion.section>
  )
}

export default FeaturesSection
