'use client'

import Image from 'next/image'
import messages from './messages'
import { Message } from '@locmod/intl'
import { Button, buttonMessages } from 'components/inputs'
import clsx from 'clsx'
import coverMobile from 'public/images/prize-pool-cover-mobile.png'
import headline from 'public/images/prize-pool-headline.png'
import { openModal } from '@locmod/modal'
import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'

type PrizePoolBannerProps = {
  className?: string
}

const PrizePoolBanner: React.FC<PrizePoolBannerProps> = ({ className }) => {
  const handleClick = () => openModal('DepositModal', { placement: 'prize pool section' })

  return (
    <div
      className={clsx(
        className,
        'flex flex-col items-end rounded-4 bg-[#8D47C7] bg-auto mb:pb-4 lg:h-[458px] lg:flex-row lg:bg-prize-pool-banner lg:bg-contain lg:pl-15',
      )}
    >
      <Image src={coverMobile} alt="a frog in a costume with a piggy bank" className="-mt-[33px] lg:hidden" />
      <div className="flex h-full max-w-[767px] flex-col items-center justify-center">
        <Image
          src={headline}
          alt="prize pool +1 000 000$ available now"
          className="mb:px-4 lg:h-[180px] lg:w-auto"
        />
        <Message
          value={messages.text}
          tag="p"
          className="mb-2 mt-4 px-4 text-center text-px-14 font-medium leading-150p text-white lg:mt-6 lg:text-px-18 lg:font-normal lg:leading-160p"
        />
        <ConnectButtonWrapper>
          <Button
            size="big"
            styling="second"
            title={buttonMessages.makeDeposit}
            leftIcon="ui/pig"
            className="mx-4 justify-center lg:mt-6"
            onClick={handleClick}
          />
        </ConnectButtonWrapper>
      </div>
    </div>
  )
}

export default PrizePoolBanner
