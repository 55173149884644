'use client'

import clsx from 'clsx'
import { Message } from '@locmod/intl'
import messages from './messages'
import Image, { StaticImageData } from 'next/image'
import memioCase from 'public/images/cases/memio.png'
import coolioCase from 'public/images/cases/coolio.png'
import extraGainCase from 'public/images/cases/extra-gain.png'
import crashCase from 'public/images/cases/crash.png'
import diceCase from 'public/images/cases/dice.png'
import wheelCase from 'public/images/cases/wheel.png'

const CASES = [
  {
    title: 'Memio?',
    description: 'Best drops!',
    image: memioCase,
  },
  {
    title: 'Coolio?',
    description: 'Best drops!',
    image: coolioCase,
  },
  {
    title: 'Extra Gain',
    description: 'Best drops!',
    image: extraGainCase,
  },
  {
    title: 'Crash',
    description: 'Best drops!',
    image: crashCase,
  },
  {
    title: 'Dice',
    description: 'Best drops!',
    image: diceCase,
  },
  {
    title: 'Wheel',
    description: 'Best drops!',
    image: wheelCase,
  },
]

type CaseSoonSectionProps = {
  className?: string
}

type CaseSoonCardProps = {
  title: string
  description: string
  image: StaticImageData
}

const CaseSoonCard: React.FC<CaseSoonCardProps> = ({ title, description, image }) => {
  return (
    <div
      className={clsx(
        'dashed-hover-border flex w-40 shrink-0 flex-col justify-center gap-2 rounded-6 bg-accent-green p-4 hover:bg-[#bfffbf] lg:w-[210px] lg:p-6',
      )}
    >
      <Image src={image} alt="lootbox" className="lg:size-fit" />
      <div className="flex flex-col gap-1">
        <span className="text-center font-black leading-150p lg:text-px-24">{title}</span>
        <span className="text-center text-px-13 font-medium leading-150p lg:text-px-14">{description}</span>
      </div>
    </div>
  )
}

const CaseSoonSection: React.FC<CaseSoonSectionProps> = ({ className }) => {
  return (
    <section className={clsx(className, 'space-y-4 lg:space-y-7')}>
      <Message
        value={messages.title}
        tag="h2"
        className="font-source-serif text-px-32 leading-120p text-grey-800 mb:text-center lg:px-10 lg:text-px-40 lg:leading-140p"
      />
      <div className="no-scrollbar flex gap-2 overflow-x-auto px-4 lg:gap-5 lg:px-10">
        {CASES.map(({ title, description, image }, index) => (
          <CaseSoonCard key={index} title={title} description={description} image={image} />
        ))}
      </div>
    </section>
  )
}

export default CaseSoonSection
