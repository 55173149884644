'use client'

import clsx from 'clsx'
import { Message } from '@locmod/intl'
import { shortenAddress, toLocaleString } from 'helpers'
import Image from 'next/image'

import { Place } from 'components/ui'
import useLeaderboard from './utils/useLeaderboard'
import messages from './messages'
import trumpet from 'public/images/trumpet-coins.png'
import trumpetMobile from 'public/images/trumpet-coins-small.png'

type LeaderboardTableProps = {
  className?: string
}

const rows: LeaderboardUser[] = new Array(10)
  .fill(null)
  .map((_, i) => ({ position: i + 1, isUser: false, total_credits: 0, user_address: '0x...' }))

type RowProps = {
  position: number
  value: number
  address: string
  isUser?: boolean
  isFetching?: boolean
}

const Row: React.FC<RowProps> = ({ position, value, address, isUser, isFetching }) => {
  const rootClassName = clsx(
    'dashed-hover-border flex h-14 items-center gap-3 rounded-5 px-2 lg:h-18 lg:gap-4 lg:px-6',
    {
      'bg-accent-green': isUser,
      'bg-white': !isUser,
    },
  )

  return (
    <li className={rootClassName}>
      <Place className="flex-none" value={position} isUser={isUser} />
      <span
        className={clsx(
          'flex flex-1 items-center text-left text-px-14 leading-none',
          isFetching ? 'animate-pulse text-black/50' : 'text-black',
        )}
      >
        <span className="font-bold">{shortenAddress(address, 4)}</span>
        {isUser && (
          <Message
            className="ml-3 inline-flex h-7 items-center rounded-full border border-dashed border-black bg-white px-3 text-px-12 uppercase text-accent1"
            value={messages.you}
          />
        )}
      </span>
      <span className="text-px-14 font-black text-accent1">
        {isFetching ? <span className="bone h-3 w-8 rounded-full" /> : toLocaleString(value, { digits: 0 })}
      </span>
    </li>
  )
}

const LeaderboardTable: React.FC<LeaderboardTableProps> = (props) => {
  const { className } = props

  const { data, isFetching } = useLeaderboard()

  return (
    <div className={clsx(className, 'relative rounded-6 bg-accent-yellow px-1 py-2 lg:px-2')}>
      <div className="flex items-center gap-3 px-2 text-px-10 font-bold uppercase text-black/70 lg:gap-4 lg:px-6 lg:text-px-12">
        <Message value={messages.place} className="w-10 flex-none" />
        <Message value={messages.user} className="flex-1" />
        <Message value={messages.total} />
      </div>
      <ul className="mt-1 grid gap-1">
        {data?.isUserOutOfTop && data?.userData && (
          <Row
            position={data.userPosition}
            address={data.userData.wallet}
            value={data.userData.profit_passive}
            isUser
          />
        )}
        {(data?.top || rows).map((item) => {
          const { user_address, total_credits, position, isUser } = item || ({} as LeaderboardUser)

          return (
            <Row
              key={position + user_address}
              position={position}
              address={user_address}
              value={total_credits || 0}
              isUser={isUser}
              isFetching={isFetching}
            />
          )
        })}
      </ul>
      <Image
        src={trumpetMobile}
        alt="Trumpet with coins"
        className="absolute -left-4 -top-16 -z-10 size-24 rotate-45 lg:hidden"
      />
      <Image
        src={trumpetMobile}
        alt="Trumpet with coins"
        className="absolute -right-4 -top-16 -z-10 size-24 -rotate-45 scale-x-[-1] lg:hidden"
      />
      <Image
        src={trumpet}
        alt="Trumpet with coins"
        className="absolute -left-60 -top-32 -z-10 h-[270px] w-[294px] mb:hidden"
      />
      <Image
        src={trumpet}
        alt="Trumpet with coins"
        className="absolute -right-60 -top-32 -z-10 h-[270px] w-[294px] scale-x-[-1] mb:hidden"
      />
    </div>
  )
}

export default LeaderboardTable
