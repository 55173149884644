import { useQuery } from '@tanstack/react-query'
import { restApi } from 'helpers'
import caseOne from 'public/images/cases/case-1.png'
import caseTwo from 'public/images/cases/case-2.png'
import caseThree from 'public/images/cases/case-3.png'
import caseElite from 'public/images/cases/case-3-big.png'
import type { StaticImageData } from 'next/image'

const pictures = [caseElite, caseTwo, caseOne, caseThree]

const fetch = async () => {
  const { data } = await restApi.get<{
    items: Box[]
    count: number
    pages: number
  }>('/boxes?sortField=price&sortMode=desc')

  return data.items.map((item, index) => ({
    ...item,
    id: +item.id || item.id,
    picture: item.picture || pictures[index] || caseOne,
  }))
}

export const useBoxes = () => {
  return useQuery({
    queryKey: ['boxes'],
    queryFn: fetch,
    refetchInterval: 300000,
  })
}
