'use client'

import { motion } from 'framer-motion'
import { Icon, IconName } from 'components/ui'
import { Button, buttonMessages } from 'components/inputs'
import messages from './messages'
import { Message } from '@locmod/intl'
import clsx from 'clsx'
import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { openModal } from '@locmod/modal'
import { constants } from 'helpers'

type RewardsSectionProps = {
  className?: string
}

const rewards: {
  title: string
  description: string
  depositButton?: boolean
}[] = [
  {
    title: 'Fast Profit!',
    description: 'Earn up to 20%<br />right away',
  },
  {
    title: 'Top-notch portfolio',
    description: 'A handpicked selection of high-potential coins',
  },
  {
    title: 'The early bird gets the worm',
    description: 'An opportunity to amplify your earnings',
  },
  {
    title: 'Start now!',
    description: 'And enjoy your experience',
    depositButton: true,
  },
]

const rewardBgs: IconName[] = ['bg-digits/one', 'bg-digits/two', 'bg-digits/three', 'bg-digits/four']

const item = {
  hidden: { x: -100, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
}

const RewardsSection: React.FC<RewardsSectionProps> = ({ className }) => {
  const handleClick = () => openModal('DepositModal', { placement: 'benefits section' })

  return (
    <motion.section
      className={clsx(className, 'flex flex-col gap-4 lg:gap-8 lg:px-10')}
      variants={constants.baseAnimationVariants.container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.6 }}
    >
      <Message
        value={messages.title}
        tag="h2"
        className="px-4 text-center font-source-serif text-px-32 leading-120p lg:text-px-40 lg:leading-140p"
      />
      <div className="no-scrollbar flex gap-2 overflow-x-scroll mb:px-4 lg:gap-5">
        {rewards.map((reward, index) => (
          <motion.div
            key={reward.description}
            variants={item}
            className="relative h-56 w-60 shrink-0 overflow-hidden rounded-5 border-2 border-dashed border-accent2 px-6 pt-6 lg:h-[268px] lg:w-[325px] lg:pt-15"
          >
            <Icon name={rewardBgs[index]} className="absolute right-0 top-0 -z-10 h-full" />
            <h3 className="font-medium leading-150p text-grey-800/70">{reward.title}</h3>
            <Message
              value={reward.description}
              tag="p"
              html
              className="mt-2 text-px-24 font-black leading-150p text-grey-800"
            />
            {reward.depositButton && (
              <ConnectButtonWrapper>
                <Button
                  title={buttonMessages.makeDeposit}
                  styling="second"
                  size="s"
                  leftIcon="ui/pig"
                  className="mt-4 lg:btn-size-big"
                  onClick={handleClick}
                />
              </ConnectButtonWrapper>
            )}
          </motion.div>
        ))}
      </div>
    </motion.section>
  )
}

export default RewardsSection
