'use client'

import clsx from 'clsx'
import { Message } from '@locmod/intl'
import messages from './messages'
import Image from 'next/image'
import caseOne from 'public/images/cases/case-1.png'
import caseTwo from 'public/images/cases/case-2.png'
import { toLocaleString } from 'helpers'
import { Icon } from 'components/ui'
import { Button, buttonMessages } from 'components/inputs'
import { openModal } from '@locmod/modal'
import { useBoxes } from 'hooks'

type OpenCaseSectionProps = {
  className?: string
}

type CaseCardProps = {
  className?: string
  data: Box | undefined
  styling?: 'default' | 'elite' | 'wide'
}

const CaseCard: React.FC<CaseCardProps> = ({ data, styling = 'default', className }) => {
  const isStylingElite = styling === 'elite'
  const isStylingDefault = styling === 'default'
  const isStylingWide = styling === 'wide'

  const rootClassName = clsx(
    className,
    isStylingElite && 'bg-tile-pattern rounded-4 p-2 lg:h-[292px] lg:rounded-5 lg:p-4',
    isStylingDefault && 'lg:h-full',
  )

  const contentClassName = clsx(
    'dashed-hover-border flex shrink-0 flex-col items-center rounded-4 p-4 lg:rounded-5 lg:p-6',
    isStylingDefault && 'gap-2 bg-white mb:w-[15.25rem] lg:h-full lg:gap-4',
    isStylingElite &&
      'gap-2 bg-accent-yellow shadow-y-4-10 lg:h-full lg:flex-wrap lg:justify-center lg:gap-6 lg:py-0 lg:pl-6 lg:pr-10',
    isStylingWide && 'gap-2 bg-white mb:w-[15.25rem] lg:h-[108px] lg:flex-row lg:gap-4',
  )

  const jettonsClassName = clsx(
    isStylingDefault && 'mb:-mt-10 lg:-mt-14',
    isStylingElite && 'mb:mt-6',
    isStylingWide && 'mb:-mt-10',
    'flex items-center gap-2 rounded-4 border border-dashed border-grey-850/50 bg-white p-2 text-px-14 font-bold leading-130p lg:rounded-5',
  )

  const imageClassName = clsx(
    isStylingDefault && 'size-[11.25rem] lg:size-[220px]',
    isStylingElite && 'size-[12.5rem] lg:size-[292px]',
    isStylingWide && 'size-[11.25rem] lg:order-first lg:size-[94px]',
  )

  const textBlockClassName = clsx(
    isStylingWide && 'lg:-order-1 lg:ml-1 lg:flex-1 lg:items-start',
    'flex flex-col gap-1',
  )

  if (!data) {
    return (
      <div className={rootClassName}>
        <div className={contentClassName}>
          <div
            className={clsx(imageClassName, 'bone bg-black/50')}
            style={{
              maskImage: `url(${caseTwo.src})`,
              maskSize: 'contain',
              maskRepeat: 'no-repeat',
              maskPosition: 'center',
            }}
          />
          <div className={clsx(jettonsClassName, 'w-24 bg-white')}>
            <div className="bone h-[1em] w-full rounded-full" />
          </div>
          <div className={textBlockClassName}>
            <div className="bone mx-auto h-4 w-32 rounded-full lg:h-5" />
            <div className="bone mx-auto h-3 w-44 rounded-full" />
          </div>
        </div>
      </div>
    )
  }

  const { id, picture, name, price, description } = data

  return (
    <div className={rootClassName}>
      <div className={contentClassName}>
        <Image src={picture || caseOne} alt="case" className={imageClassName} />
        <div className={jettonsClassName}>
          <Icon name="ui/coin" className="size-6" />
          <div className="space-x-1">
            <span>{toLocaleString(price, { digits: 0 })}</span>
            <Message value={messages.credits} />
          </div>
        </div>
        <div className={textBlockClassName}>
          <Message value={name} className="text-center text-px-20 font-black leading-150p lg:text-px-24" />
          <Message value={description} className="text-center text-px-14 font-medium leading-150p" />
        </div>
        <Button
          size="s"
          styling="main"
          title={buttonMessages.openCase}
          className={clsx(isStylingElite && 'mb:btn-size-big', 'lg:btn-size-big')}
          onClick={() => {
            openModal('LootboxModal', { lootbox: data })
          }}
        />
      </div>
    </div>
  )
}

const OpenCaseSection: React.FC<OpenCaseSectionProps> = ({ className }) => {
  const { isFetching, data } = useBoxes()

  return (
    <section id="cases" className={clsx(className, 'scroll-m-5 space-y-4 lg:space-y-7')}>
      <Message
        value={messages.title}
        tag="h2"
        className="px-4 font-source-serif text-px-40 leading-140p text-grey-800 lg:px-10"
      />
      <div className="grid grid-cols-1 items-stretch gap-2 mb:flex-col lg:grid-flow-dense lg:grid-cols-4 lg:gap-5 lg:px-10">
        <div className="mb:px-4 lg:col-span-2">
          <CaseCard data={data?.[0]} styling="elite" />
        </div>
        <div className="no-scrollbar flex gap-2 overflow-x-auto mb:px-4 lg:contents">
          {!data ? (
            <>
              <CaseCard className="lg:row-span-2" data={undefined} styling="default" />
              <CaseCard className="lg:row-span-2" data={undefined} styling="default" />
              <CaseCard className="lg:col-span-2" data={undefined} styling="wide" />
            </>
          ) : (
            data.slice(1).map((item, index) => {
              return (
                <CaseCard
                  key={item.id}
                  className={index === 2 ? 'lg:col-span-2' : 'lg:row-span-2'}
                  data={item}
                  styling={index === 2 ? 'wide' : 'default'}
                />
              )
            })
          )}
        </div>
      </div>
    </section>
  )
}

export default OpenCaseSection
