import { useQuery } from '@tanstack/react-query'
import { restApi } from 'helpers'

const fetch = async () => {
  const { data } = await restApi.get<{
    items: Game[]
    count: number
    pages: number
  }>('/games')

  return data.items
}

export const useGameHistory = () => {
  return useQuery({
    queryKey: ['games'],
    queryFn: fetch,
    refetchInterval: 60000,
  })
}
